<template>
  <v-container :fluid="$vuetify.breakpoint.lgAndDown">
    <v-row>
      <v-col>
        <!-- ---------------------------------------------------------------------------------------------------------- ->
        <! all order fields                                                                                             ->
        <!- ------------------------------------------------------------------------------------------------------------->
        <v-row>
          <!-- -------------------------------------------------------------------------------------------------------- ->
          <! order identification                                                                                       ->
          <!- ----------------------------------------------------------------------------------------------------------->
          <v-col lg="2" md="6" sm="6" cols="6" order="first">
            <v-card outlined>
              <v-card-title>Status</v-card-title>
              <v-col class="py-0 my-0">
                <w-text-field :text="order.orderClientReference" label="client reference" />
                <w-text-field :text="formatDate(order._creation)" label="creation" />
                <w-text-field :text="order.orderStatusString" label="status" />
                <w-text-field :text="order.orderReference" label="HAMP reference" />
                <w-text-field :text="order.orderCustomReference" label="custom reference" />
              </v-col>
            </v-card>
          </v-col>

          <!-- -------------------------------------------------------------------------------------------------------- ->
          <! client identification                                                                                      ->
          <!- ----------------------------------------------------------------------------------------------------------->
          <v-col lg="7" md="12" sm="12" cols="12" order-md="last" order-sm="last" order="last">
            <v-card outlined>
              <v-card-title>Client</v-card-title>
              <v-col>
                <v-row>
                  <v-col cols="4" class="py-0 my-0">
                    <w-text-field
                      :text="order.client.name"
                      label="client"
                      :back-to="readOrdersPath"
                    />
                  </v-col>
                  <v-col cols="4" class="py-0 my-0">
                    <w-text-field :text="order.zone" label="zone" />
                  </v-col>
                  <v-col cols="4" class="py-0 my-0">
                    <w-text-field :text="order.shop" label="shop" />
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="4" class="py-0 my-0">
                    <w-text-field :text="order.agentID" label="agent ID" />
                  </v-col>
                  <v-col cols="4" class="py-0 my-0">
                    <w-text-field :text="order.agentCode" label="agent code" />
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="4" class="py-0 my-0">
                    <w-text-field :text="order.receiverID" label="receiver ID" />
                  </v-col>
                  <v-col cols="4" class="py-0 my-0">
                    <w-text-field :text="order.receiverMail" label="receiver mail" />
                  </v-col>
                  <v-col cols="4" class="py-0 my-0">
                    <w-text-field :text="order.receiverPhone" label="receiver phone" />
                  </v-col>
                </v-row>

                <v-row>
                  <v-col class="py-0 my-0">
                    <w-text-field :text="receiverAddress" label="receiverAddress" />
                  </v-col>
                </v-row>
              </v-col>
            </v-card>

            <v-card outlined class="mt-4">
              <v-card-title>
                <span style="padding-left:12px">Misc</span>
                <v-spacer></v-spacer>
                <v-btn icon @click="showMisc = !showMisc">
                  <v-icon>{{ showMisc ? "mdi-chevron-up" : "mdi-chevron-down" }}</v-icon>
                </v-btn>
              </v-card-title>

              <!-- <v-expand-transition> -->
              <v-col v-show="showMisc">
                <v-row>
                  <v-col cols class="py-0 my-0">
                    <w-text-field :text="order.comment" label="comment" />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols class="py-0 my-0">
                    <w-text-field :text="order.clientFreeText" label="client free text" />
                  </v-col>
                </v-row>
              </v-col>
              <!-- </v-expand-transition> -->
            </v-card>
          </v-col>

          <!-- -------------------------------------------------------------------------------------------------------- ->
          <! client identification                                                                                      ->
          <!- ----------------------------------------------------------------------------------------------------------->
          <v-col lg="3" md="6" sm="6" cols="6" order-lg="last">
            <v-card outlined>
              <v-card-title>Delivery settings</v-card-title>

              <v-col>
                <v-row>
                  <v-col class="py-0 my-0">
                    <w-text-field :text="order.expectedDeliveryString" label="expected delivery" />
                  </v-col>
                </v-row>

                <v-row no-gutters>
                  <v-col class="py-0 my-0">
                    <w-checkbox :value="order.forceCompleteDelivery" label="force complete"></w-checkbox>
                  </v-col>
                </v-row>

                <v-row no-gutters>
                  <v-col class="py-0 my-0">
                    <w-text-field :text="order.delivererCode" label="deliverer code" />
                  </v-col>
                </v-row>

                <v-row no-gutters>
                  <v-col class="py-0 my-0">
                    <w-checkbox :value="order.isNormalDelivery" label="normal"></w-checkbox>
                    <w-checkbox :value="order.isExpressDelivery" label="express"></w-checkbox>
                  </v-col>
                  <v-col class="py-0 my-0">
                    <w-checkbox :value="order.isProxi" label="proxi"></w-checkbox>
                    <w-checkbox :value="order.isCourierDelivery" label="courier"></w-checkbox>
                    <w-checkbox :value="order.isPickUp" label="pick up"></w-checkbox>
                  </v-col>
                </v-row>
              </v-col>
            </v-card>
          </v-col>

          <!-- ---------------------------------------------------------------------------------------------------------- ->
          <! / all order fields                                                                                           ->
          <!- ------------------------------------------------------------------------------------------------------------->
        </v-row>
        <!-- ---------------------------------------------------------------------------------------------------------- ->
        <! Items                                                                                                        ->
        <!- ------------------------------------------------------------------------------------------------------------->
        <v-row>
          <v-col>
            <v-card class="elevation-6">
              <w-entity-list
                :columns="columns"
                :items="order.items"
                :read-path="readItemPath"
                read-ref="itemReference"
                title="Items"
              />
            </v-card>
          </v-col>
        </v-row>
        <!-- --------------------------------------------------------------------------------------------------------- -->
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {
  VBtn,
  VCard,
  VCardTitle,
  VCol,
  VContainer,
  //   VExpandTransition,
  VIcon,
  VRow,
  VSpacer,
} from "vuetify/lib";
import WCheckbox from "../../components/external/modules/shared/views/WCheckbox.vue";
import WEntityList from "../../components/external/modules/shared/views/WEntityList.vue";
import WTextField from "../../components/external/modules/shared/views/WTextField";
import api from "../../components/external/modules/shared/utils/api";
import path from "../../shared/paths";
import datehourformat from "../../components/external/modules/shared/utils/datehourformat";

function tweakItem(item) {
  // product reference
  let productRefs = [];
  if (item.productEANReference) {
    productRefs.push(item.productEANReference);
  }
  if (item.productManufacturerReference) {
    productRefs.push(item.productManufacturerReference);
  }
  if (item.productProviderReference && productRefs.length === 0) {
    productRefs.push(item.productProviderReference);
  }
  item.productReference = productRefs.join(" / ");

  // quantities
  item.quantities = "0 / ";
  if (item.confirmedQuantity > 0) {
    item.quantities = item.confirmedQuantity + " / ";
  }
  item.quantities = item.quantities + item.orderedQuantity;
}

export default {
  components: {
    VBtn,
    VCard,
    VCardTitle,
    VCol,
    VContainer,
    VIcon,
    VRow,
    VSpacer,
    WCheckbox,
    WEntityList,
    WTextField,
  },

  data: () => ({
    order: {
      client: {},
    },

    columns: [
      { value: "num", text: "num" },
      { value: "productReference", text: "prod. ref." },
      { value: "confirmedProvider", text: "provider" },
      { value: "providerProductLabel", text: "label" },
      { value: "productCustomReference", text: "client ref." },
      { value: "techStatusString", text: "status" },
      { value: "quantities", text: "conf. quant." },
      { value: "deliveryDayString", text: "delivery" },
    ],

    readItemPath: path.ORDERITEM,
    readOrdersPath: path.ORDERS,

    showMisc: false,
  }),

  computed: {
    receiverAddress: function () {
      if (this.order.receiverAddress) {
        return this.order.receiverAddress.label;
      }
      return undefined;
    },
  },

  created() {
    api
      .get("/purchaseorder/read/" + this.$route.params.which)
      .then((res) => {
        if (res.data.status < 400 && res.data.entity !== null) {
          this.order = res.data.entity;
          this.order.items.forEach((item) => tweakItem(item));
        } else {
          console.log(new Error("Failed loading the order"));
          console.log(res);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  },

  methods: {
    formatDate(date) {
      return datehourformat(date);
    },
  },
};
</script>

<style scoped>
</style>
